import { useState, useEffect } from "react";

import { Link, NavLink, useLocation } from "react-router-dom";

import { Container } from "@mui/material";

import { Text, Menu, Button, rem, Burger } from "@mantine/core";
import { useDisclosure, useWindowScroll } from "@mantine/hooks";

import { motion } from "framer-motion";
import Logo from "../../Assets/IMG/LogoFull.svg";
import "./styles.scss";
import {
  IconDialpad,
  IconDialpadFilled,
  IconHome,
  IconNews,
  IconSearch,
  IconShovel,
  IconSitemap,
  IconStars,
} from "@tabler/icons-react";

export default function Navbar() {
  const location = useLocation();
  const [scroll, scrollTo] = useWindowScroll();

  const [isWhite, setWhite] = useState<boolean>(true);
  const [activeLink, setActiveLink] = useState<string>("");

  const NavLinks = [
    { link: "", label: "Home", icon: IconHome },
    { link: "about", label: "About", icon: IconSitemap },
    { link: "transform-my-pond", label: "Transform My Pond", icon: IconShovel },
    { link: "farmers", label: "Farmers", icon: IconStars },
    { link: "news", label: "News", icon: IconNews },
    { link: "#", label: "Contact", scroll: true, icon: IconDialpadFilled },
  ];

  useEffect(() => {
    const { pathname } = location;
    setActiveLink(pathname.replaceAll("/", "").toLowerCase());
  }, [location]);
  useEffect(() => {
    setWhite(scroll.y > 10);
  }, [scroll]);
  const [isMenuOpened, { toggle: ToggleMenu }] = useDisclosure(false);
  const services = [
    "farm-automation",
    "pond-monitoring",
    "security-system",
    "consultation",
  ];
  return (
    <>
      <motion.div
        initial={false}
        animate={{
          backgroundColor: isWhite ? "var(--white)" : "transparent",
        }}
        transition={{
          duration: 5,
        }}
        className={`flex-row align-center justify-center navbar-container `}
      >
        <Container maxWidth="lg">
          <nav className="nav flex-row align-center justify-between width-100">
            <Link to="/">
              <img src={Logo} alt="" className="logo" />
            </Link>
            <div className="items flex-row align-center justify-between">
              {NavLinks.map((link) => {
                return (
                  <>
                    {link.link === "transform-my-pond" ? (
                      <Menu
                        trigger="hover"
                        styles={{
                          dropdown: { zIndex: 9999999 },
                        }}
                        transitionProps={{
                          transition: "rotate-left",
                          duration: 150,
                        }}
                      >
                        <Menu.Target>
                          <Link
                            className={`item ${
                              activeLink.toLowerCase() ===
                              link.link.toLowerCase()
                                ? "item-active"
                                : ""
                            }`}
                            key={link.label}
                            to={`/${link.link}`}
                          >
                            <Text className="link">{link.label}</Text>
                          </Link>
                        </Menu.Target>
                        <Menu.Dropdown>
                          {services.map((service) => {
                            return (
                              <Link
                                to={`/service/${service}`}
                                className="small-nav-link"
                              >
                                <Menu.Item mb={10} tt="capitalize">
                                  {service.replaceAll("-", " ")}
                                </Menu.Item>
                              </Link>
                            );
                          })}
                        </Menu.Dropdown>
                      </Menu>
                    ) : (
                      <>
                        {link.scroll ? (
                          <a href="#contact" className="item">
                            <Text className="link">{link.label}</Text>
                          </a>
                        ) : (
                          <Link
                            className={`item ${
                              activeLink.toLowerCase() ===
                              link.link.toLowerCase()
                                ? "item-active"
                                : ""
                            }`}
                            key={link.label}
                            to={`/${link.link}`}
                          >
                            <Text className="link">{link.label}</Text>
                          </Link>
                        )}
                      </>
                    )}
                  </>
                );
              })}
            </div>
          </nav>
        </Container>
      </motion.div>
      <motion.div
        className="small-nav flex-row align-center justify-between width-100"
        animate={{
          backgroundColor: isWhite ? "var(--white)" : "transparent",
        }}
      >
        <img src={Logo} alt="" className="small-logo" />
        <Menu
          opened={isMenuOpened}
          styles={{
            dropdown: { zIndex: 9999999 },
          }}
          onChange={ToggleMenu}
          transitionProps={{ transition: "rotate-right", duration: 150 }}
        >
          <Menu.Target>
            <Burger
              style={{
                zIndex: 9999999,
              }}
              opened={isMenuOpened}
              onClick={ToggleMenu}
              className="toggle-nav"
            />
          </Menu.Target>
          <Menu.Dropdown>
            {NavLinks.map((link) => {
              return (
                <>
                  {link.scroll ? (
                    <a href="#contact" className="small-nav-link">
                      <Menu.Item
                        mb={10}
                        leftSection={
                          <link.icon
                            style={{ width: rem(14), height: rem(14) }}
                            color="var(--blue-primary)"
                          />
                        }
                      >
                        {link.label}
                      </Menu.Item>
                    </a>
                  ) : (
                    <Link to={link.link} className="small-nav-link">
                      <Menu.Item
                        mb={10}
                        leftSection={
                          <link.icon
                            style={{ width: rem(14), height: rem(14) }}
                            color="var(--blue-primary)"
                          />
                        }
                      >
                        {link.label}
                      </Menu.Item>
                    </Link>
                  )}
                </>
              );
            })}
          </Menu.Dropdown>
        </Menu>
      </motion.div>
    </>
  );
}

import { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";

import { Container } from "@mui/material";

import { Text, Button, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Carousel } from "@mantine/carousel";
import Autoplay from "embla-carousel-autoplay";
import { motion } from "framer-motion";

import ArrowRightIcon from "../../../Assets/IMG/ArrowRight.svg";

import CarouselImage1 from "../../../Assets/IMG/Home/JumboCarousel/1.jpg";
import CarouselImage2 from "../../../Assets/IMG/Home/JumboCarousel/2.jpg";
import CarouselImage3 from "../../../Assets/IMG/Home/JumboCarousel/3.jpg";
import CarouselImage4 from "../../../Assets/IMG/Home/JumboCarousel/4.jpg";
import CarouselImage5 from "../../../Assets/IMG/Home/JumboCarousel/5.jpg";

import "./styles.scss";
export default function HomeJumbotron() {
  const images = [
    CarouselImage1,
    CarouselImage2,
    CarouselImage3,
    CarouselImage4,
    CarouselImage5,
  ];
  const [currentImage, setCurrentImage] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
    }, 9000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="jumbotron-container">
      <div className="jumbo-bg"></div>
      <Container maxWidth="lg">
        <div className="jumbotron flex-row justify-between">
          <div className="left flex-col justify-between">
            <Text className="header">
              The <br />
              Future <br />
              Aquaculture
            </Text>
            <Text className="subtitle" data-aos="fade-down">
              Empowering African Fish Farmers with Smart and Efficient Solutions
              for a Sustainable Future
            </Text>
            <div className="left-row flex-row align-center" data-aos="fade-up">
              <Link to="/about">
                <Button className="btn " color="var(--blue-primary)">
                  About Us
                </Button>
              </Link>
              <a href="#reviews">
                <Button
                  className="btn reviews-btn"
                  variant="subtle"
                  color="transparent"
                  c="var(--darkest-green)"
                  fw={500}
                  rightSection={
                    <img className="arrow-icon" src={ArrowRightIcon} />
                  }
                >
                  Reviews
                </Button>
              </a>
            </div>
          </div>
          <div className="right">
            {images.map((image, index) => {
              return (
                <motion.div
                  animate={{
                    opacity: currentImage === index ? 1 : 0,
                  }}
                  transition={{ duration: 3 }} // Prolong the animation duration to 5 seconds
                  className="image"
                  key={`${index}-${image}`}
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                ></motion.div>
              );
            })}
          </div>
        </div>
      </Container>
    </div>
  );
}
